// import PropTypes from 'prop-types';
import React from 'react';
import styles from './RecentProducts.module.scss';
import { FormattedHTMLMessage } from 'react-intl';
import PageSectionHeader from '../PageSectionHeader/PageSectionHeader';
import { useSearchProducts } from '../../utils/useSearchProducts';
import Product from '../Product/Product';
import { clipTextEllipsis } from '../../utils/textEllipsis';
import { useSliderScroll } from '../Sliders/useSliderScroll';
import Sliders from '../Sliders/Sliders';
import { SLIDER_CUSTOM_NAV } from '../Sliders/Sliders.def';
import { ARD_CATEGORY_SEARCH } from '../../utils/appRoutes.definitions';
import { getPagePath } from '../../utils/appRoutes';
import { PRODUCT_SECTION_CATEGORY_NEW } from '../Product/ProductCardOriginType.def';

const RecentProducts = ({ categoryPath }) => {
  const [recentProducts] = useSearchProducts(categoryPath);
  const {
    onScrollForward,
    onScrollBack,
    scrollLeft,
    isScrolledToEnd,
    sliderContainerRef,
    onScroll,
  } = useSliderScroll();

  if (!recentProducts?.length) return;

  return (
    <section className={styles.root}>
      <PageSectionHeader withSliderControls sliderNext={onScrollForward} sliderPrevious={onScrollBack}>
        <FormattedHTMLMessage
          tagName="div"
          defaultMessage="<span>Recent</span> products"
          id="category.recentProducts"
        />
      </PageSectionHeader>
      <div className="container">
        <Sliders
          classList={{ root: styles.slidersRoot, slides: styles.slidesWrapper, card: styles.productCardWrapper }}
          testId="newproductsslider"
          link={`${getPagePath(ARD_CATEGORY_SEARCH, categoryPath)}?sortBy=new`}
          type={SLIDER_CUSTOM_NAV}
          scrollLeft={scrollLeft}
          isScrolledToEnd={isScrolledToEnd}
          sliderContainerRef={sliderContainerRef}
          viewAllAsCard
          onScroll={onScroll}
          viewAll
        >
          {recentProducts.map((product, index) => (
            <Product
              adult={product?.adult}
              shopRating={product.shopRating?.rating}
              classList={{ layout: styles.product }}
              currency={product.currency}
              shortDesc={product.shortDesc && clipTextEllipsis(product.shortDesc, 100)}
              price={product.price}
              originalPrice={product.price_original || product.price}
              key={product.id}
              id={product.id}
              image={product.thumbs?.[0]?.url}
              name={product.name}
              // onClick={onProductClick(product.id, product.canonical_path)}
              shop={{ name: product.campaign }}
              url={product.canonical_path}
              status={product.new}
              isInViewPort={false}
              //todo: check why layout glitches with showProductLink set to true
              showProductLink={false}
              shopId={product?.shop_id}
              merchantPid={product?.merchant_product_id}
              resultRank={index}
              category={product?.category}
              productCardOriginType={PRODUCT_SECTION_CATEGORY_NEW}
            />
          ))}
        </Sliders>
      </div>
    </section>
  );
};

export default RecentProducts;
